import React, { useState, useEffect } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import logo from "../assets/marshall2.png";
import gifLogo from "../assets/Book.gif";
import { MdLogout } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { IoCreateOutline } from "react-icons/io5";
import { AiOutlineHistory } from "react-icons/ai";
import { HiMenuAlt3 } from "react-icons/hi";
import { GrView } from "react-icons/gr";
import { GoHistory } from "react-icons/go";
import { LuBookDown, LuContact2 } from "react-icons/lu";
import { LuFileQuestion } from "react-icons/lu";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { MdManageSearch } from "react-icons/md";
import { MdSpatialAudio } from "react-icons/md";
import Spinner from "./SpinnerDs.js";
import Dropdown from "react-dropdown";
import axios from "axios";
import DropFileInput from "./DropFileInput.js";
import "./AudioLearning.css";

const AudioLearning = (props) => {
  const [kontenVis, setKontenVis] = useState(true);
  const [loadingVis, setLoadingVis] = useState(false);
  const [doneVis, setDoneVis] = useState(false);

  // Loading Spinner, Error, dan Proses upload
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [prosesUpload, setProsesUpload] = useState("0");

  const [urlAudio, setUrlAudio] = useState(
    "https://api-stag.marshall-teach.web.id//modules/e6bb1b89-ac77-4bff-88e8-1d9a1d30b772/Satellite Communications Systems - Gerard Maral, Michel Bousquet, Zhili Sun.pdf.wav"
  );
  const [namaFile, setNamaFile] = useState("");

  const filteredData = [
    {
      title: "Chapter 1: An Introduction to Deep Learning and Keras",
      index: 20,
    },
    {
      title: "Chapter 2: Keras in Action",
      index: 36,
    },
    {
      title:
        "Chapter 3: Deep Neural Networks for Supervised Learning: Regression",
      index: 72,
    },
    {
      title:
        "Chapter 4: Deep Neural Networks for Supervised Learning: Classification",
      index: 120,
    },
    {
      title: "Chapter 5: Tuning and Deploying Deep Neural Networks",
      index: 156,
    },
  ];

  const convertBaru = (e) => {
    e.preventDefault();

    setKontenVis(true);
    setDoneVis(false);
  };

  // RESET ALL TOKEN
  useEffect(() => {
    props.reset();
  }, []);

  // Token & Status
  const [tokenAudio, setTokenAudio] = useState("");
  const [statusAudio, setStatusAudio] = useState("");

  // Inisiasi Interval 3 detik
  const [start, setStart] = useState(false);

  const [files, setFiles] = useState("");
  const options_jenisInputan = ["Ebook (PDF)", `Modul (PPTX)`];
  const [jenisInputan, setJenisInputan] = useState(options_jenisInputan[0]);

  // DropFile Variable
  const [logoInput, setLogoInput] = useState("pdf.png");
  const [dropname, setDropName] = useState("PDF");
  const [accFile, setAccFile] = useState(".pdf");

  const [removeStatus, setRemoveStatus] = useState(false);

  // Abstrak PDF + Translate
  const [wordsValue, setWordsValue] = useState("3000");
  const options_translate = [
    "Same as Input",
    "Indonesia (Auto Translate)",
    `Inggris (Auto Translate)`,
  ];
  const [translateBhs, setTranslateBhs] = useState(options_translate[0]);
  const [abstrakCheck, setAbstrakCheck] = useState(false);

  const onFileChange = (e) => {
    setFiles(e[0]);
    console.log("Hasil upload : ", e[0]);
  };

  const handleJenisChange = (e) => {
    setJenisInputan(e.value);
    console.log(e.value);

    setRemoveStatus(true);
    // console.log(data);

    if (e.value.includes("Ebook")) {
      setLogoInput("pdf.png");
      setDropName("PDF");
      setAccFile(".pdf");
    } else if (e.value.includes("Modul")) {
      setLogoInput("pptx.png");
      setDropName("PPTX");
      setAccFile(".pptx");
    }
  };

  const handleResetRemove = () => {
    setRemoveStatus(false);
  };

  // Axios
  const audioEbook = async (formData, maxAttempts) => {
    let statusStore = "";
    let attempts = 0;

    const converting = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/new-ebook-to-audio/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 1200000, // 20 Menit
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(`Progress: ${percentCompleted}%`);
              if (percentCompleted < 60) {
                setLoadingSpinner(true);
                setProsesUpload(percentCompleted);
              }
              // Anda bisa menggunakan setState atau fungsi lain untuk memperbarui UI di sini
            },
          }
        );

        if (
          response.data.your_request_token &&
          response.data.your_request_token.length > 0
        ) {
          setLoadingSpinner(false);
          setLoadingVis(true);

          const data = response.data.your_request_token;
          const status = response.data.status;

          statusStore = data;

          // console.log("TOKEN NYA:", data);
          console.log("STATUS AUDIO NYA:", status);

          setTokenAudio(data);
          setStatusAudio(status);

          return true; // Selesai dengan sukses
        } else {
          console.log("Gagal Convert, mencoba lagi...");
          return false; // Gagal, akan mencoba lagi
        }
      } catch (error) {
        console.error("Error message:", error.message);

        if (error.response) {
          console.error("Server responded with:", error.response.status);
          setErrorMsg("Server responded with:", error.response.status);
        } else if (error.request) {
          console.error("No response received:", error.request);
          setErrorMsg("No response received:", error.request);
        } else {
          console.error("Axios config error:", error.message);
          setErrorMsg("Axios config error:", error.message);
        }

        return false; // Error, akan mencoba lagi
      }
    };

    while (statusStore === "" && attempts < maxAttempts) {
      attempts += 1;
      const isSuccessful = await converting();

      if (isSuccessful) {
        setStart(true);
        break;
      } else if (attempts < maxAttempts) {
        console.log(
          `Menunggu 5 detik sebelum mencoba lagi (${attempts}/${maxAttempts})...`
        );
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    }

    if (statusStore === "") {
      console.log(`Gagal Converting Ebook setelah ${maxAttempts} percobaan.`);
    }

    return statusStore;
  };

  const audioModule = async (formData, maxAttempts) => {
    let statusStore = "";
    let attempts = 0;

    const converting = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/new-module-to-audio/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 1200000, // 20 Menit
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(`Progress: ${percentCompleted}%`);
              if (percentCompleted < 60) {
                setLoadingSpinner(true);
                setProsesUpload(percentCompleted);
              }
              // Anda bisa menggunakan setState atau fungsi lain untuk memperbarui UI di sini
            },
          }
        );

        if (
          response.data.your_request_token &&
          response.data.your_request_token.length > 0
        ) {
          setLoadingSpinner(false);
          setLoadingVis(true);

          const data = response.data.your_request_token;
          const status = response.data.status;

          statusStore = data;

          // console.log("TOKEN NYA:", data);
          console.log("STATUS AUDIO NYA:", status);

          setTokenAudio(data);
          setStatusAudio(status);

          return true; // Selesai dengan sukses
        } else {
          console.log("Gagal Convert, mencoba lagi...");
          return false; // Gagal, akan mencoba lagi
        }
      } catch (error) {
        console.error("Error message:", error.message);

        if (error.response) {
          console.error("Server responded with:", error.response.status);
          setErrorMsg("Server responded with:", error.response.status);
        } else if (error.request) {
          console.error("No response received:", error.request);
          setErrorMsg("No response received:", error.request);
        } else {
          console.error("Axios config error:", error.message);
          setErrorMsg("Axios config error:", error.message);
        }

        return false; // Error, akan mencoba lagi
      }
    };

    while (statusStore === "" && attempts < maxAttempts) {
      attempts += 1;
      const isSuccessful = await converting();

      if (isSuccessful) {
        setStart(true);
        break;
      } else if (attempts < maxAttempts) {
        console.log(
          `Menunggu 5 detik sebelum mencoba lagi (${attempts}/${maxAttempts})...`
        );
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    }

    if (statusStore === "") {
      console.log(`Gagal Converting Module setelah ${maxAttempts} percobaan.`);
    }

    return statusStore;
  };
  ////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Ambil Status Generate per interval 3 detik

  const fetchData = async () => {
    const formData = new FormData();
    formData.append("request_token", tokenAudio);

    // console.log("Token Audio : ", tokenAudio);
    try {
      if (jenisInputan === "Ebook (PDF)") {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/result-ebook-to-audio/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
          }
        );
        let status = res.data.status;
        let data = res.data.url_audio;

        console.log("Status : ", status);
        console.log("Data : ", data);

        setStatusAudio(status);

        if (data.length != 0) {
          setUrlAudio(data);
        }
        if (status === "done") {
          console.log("Proses Selesai");
          setStart(false);
        }
      } else if (jenisInputan === "Modul (PPTX)") {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/result-module-to-audio/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
          }
        );
        let status = res.data.status;
        let data = res.data.url_audio;

        console.log("Status : ", status);
        console.log("Data : ", data);

        setStatusAudio(status);

        if (data.length != 0) {
          setUrlAudio(data);
        }
        if (status === "done") {
          console.log("Proses Selesai");
          setStart(false);
        }
      }
    } catch (error) {
      console.error("Error saat memanggil API:", error);
    }
  };

  useEffect(() => {
    let interval;
    console.log("TERPANGGIL");

    if (start) {
      interval = setInterval(async () => {
        await fetchData(); // Call the fetchData function here
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [start, tokenAudio]);

  // SETTINGS DENGAN STATUS
  useEffect(() => {
    console.log("Settings Loading dengan Status");
    console.log("+" + statusAudio + "+");
    // console.log("Token Req : ", tokenAudio);

    if (statusAudio === "done") {
      console.log("DONEEEEEEEEEEEEEEEEEEEEEE");
      setKontenVis(false);
      setDoneVis(true);
      setLoadingVis(false);

      setTokenAudio("");
      setStatusAudio("");
    }
    // Jika selain "Done"
    else if (statusAudio === "processing") {
      console.log("PROCESINGGGGGGGGGGGGGG");
      setKontenVis(false);
      setDoneVis(false);
      setLoadingVis(true);
      setLoadingSpinner(false);
    } else if (statusAudio === "waiting") {
      console.log("WAITINGGGGGGGGGGGGGGGGGGGGGGG");
      setKontenVis(false);
      setDoneVis(false);
      setLoadingVis(true);
      setLoadingSpinner(false);
    }
  }, [statusAudio]);
  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (urlAudio) {
      console.log("urlAudio Updated: ", urlAudio);
    }
  }, [urlAudio]);

  // SET SHOW TABLE DARI REQUEST
  const fetchDataReq = async () => {
    const formData = new FormData();
    formData.append("request_token", props.tokenAudio);

    console.log("Detail Mapping : ", props.detailAudio);

    // console.log("Token Mapping : ", tokenAudio);
    try {
      if (props.detailAudio.includes(".pdf")) {
        console.log("Detail Mapping PDF");
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/result-ebook-to-audio/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
          }
        );
        let status = res.data.status;
        let data = res.data.url_audio;

        console.log("Status : ", status);
        console.log("Data : ", data);

        if (data.length != 0) {
          setUrlAudio(data);
        }
        if (status === "done") {
          console.log("Proses Selesai");
        }
      } else if (props.detailAudio.includes(".pptx")) {
        console.log("Detail Mapping PPTX");
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/result-module-to-audio/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
          }
        );
        let status = res.data.status;
        let data = res.data.url_audio;

        console.log("Status : ", status);
        console.log("Data : ", data);

        if (data.length != 0) {
          setUrlAudio(data);
        }
        if (status === "done") {
          console.log("Proses Selesai");
        }
      }
    } catch (error) {
      console.error("Error saat memanggil API:", error);
    }
  };

  useEffect(() => {
    if (props.tokenAudio.length > 1) {
      setTokenAudio(props.tokenAudio);
    }
  }, [props.tokenAudio]);

  useEffect(() => {
    if (props.tokenAudio === undefined || props.tokenAudio === "") {
      // Nothing
    } else {
      // console.log("INI TOKEN REQQQQQQQQQ : " + props.tokenAudio);
      console.log("INI STATUS REQQQQQQQQ : " + props.statusAudio);

      if (props.tokenAudio.length > 5) {
        console.log("ADA TOKEN REQ NIHHH");

        if (props.statusAudio !== "done") {
          setStart(true);
        } else {
          fetchDataReq();
        }
        setNamaFile(props.detailAudio);
        setStatusAudio(props.statusAudio);

        if (props.statusAudio === "error") {
          alert("STATUS ERROR : EBOOK/MODULE BERMASALAH");
          props.resetToken("");
          props.resetStatus("");
          props.resetDetail("");
        } else if (props.statusAudio === "processing") {
          setKontenVis(false);
          setDoneVis(false);
          setLoadingVis(true);
        } else if (props.statusAudio === "waiting") {
          setKontenVis(false);
          setDoneVis(false);
          setLoadingVis(true);
        } else if (props.statusAudio === "done") {
          setKontenVis(false);
          setDoneVis(true);
          setLoadingVis(false);
        }
      } else {
        // Nothing
      }
    }
  }, [props.tokenAudio]);

  ////////////////////////////////////////////////////////////////////////////////////////////

  const handleClickAudio = (e) => {
    e.preventDefault();

    console.log("Words : ", wordsValue);
    console.log("Jenis Inputan : ", jenisInputan);
    console.log("Output Audio Language : ", translateBhs);
    console.log("Files : ", files);
    console.log("Check Abstrak : ", abstrakCheck);

    if (files === undefined || files === "") {
      alert("Harap Upload Ebook / Module Terlebih Dahulu");
    } else {
      setKontenVis(false);
      setNamaFile(files.name);

      if (jenisInputan === "Ebook (PDF)") {
        const formData = new FormData();
        formData.append("file_pdf", files);
        formData.append("id_user", props.user.id_user);
        if (translateBhs === "Same as Input") {
          const filterBhs = "default";
          formData.append("output_audio_language", filterBhs);
        } else if (translateBhs === "Indonesia (Auto Translate)") {
          const filterBhs = "id";
          formData.append("output_audio_language", filterBhs);
        } else if (translateBhs === "Inggris (Auto Translate)") {
          const filterBhs = "en";
          formData.append("output_audio_language", filterBhs);
        }
        formData.append("check_abstract_pdf", abstrakCheck);
        formData.append("num_words", wordsValue);

        const callAudio1 = async () => {
          const hasil = await audioEbook(formData, 5);
          if (hasil === "" || hasil === null) {
            alert("GAGAL CONVERTING EBOOK => " + errorMsg);
          }
        };

        callAudio1();
      } else if (jenisInputan === "Modul (PPTX)") {
        const formData = new FormData();
        formData.append("file_pptx", files);
        formData.append("id_user", props.user.id_user);
        if (translateBhs === "Same as Input") {
          const filterBhs = "default";
          formData.append("output_audio_language", filterBhs);
        } else if (translateBhs === "Indonesia (Auto Translate)") {
          const filterBhs = "id";
          formData.append("output_audio_language", filterBhs);
        } else if (translateBhs === "Inggris (Auto Translate)") {
          const filterBhs = "en";
          formData.append("output_audio_language", filterBhs);
        }
        formData.append("check_abstract_pdf", abstrakCheck);
        formData.append("num_words", wordsValue);

        const callAudio2 = async () => {
          const hasil = await audioModule(formData, 5);
          if (hasil === "" || hasil === null) {
            alert("GAGAL CONVERTING MODULE => " + errorMsg);
          }
        };

        callAudio2();
      }
    }
  };

  const handleInputWords = (e) => {
    const inputValue = e.target.value;

    // Hanya mengizinkan angka
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setWordsValue(inputValue);
    }

    // Jika input kosong, biarkan kosong
    if (inputValue === "") {
      setWordsValue("");
      return;
    }

    // Mengubah nilai input menjadi angka
    const newVal = parseInt(inputValue, 10);

    // Validasi input angka
    if (isNaN(newVal)) {
      setWordsValue("3000");
    } else {
      setWordsValue(newVal);
    }
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      setWordsValue("3000");
    } else if (e.target.value < 3000) {
      setWordsValue("3000");
    }
  };

  const onCheckAbstrak = (e) => {
    setAbstrakCheck(!abstrakCheck);
  };

  const downloadAudioClick = (e) => {
    e.preventDefault();

    // const link = document.createElement("a");
    // link.href = download;
    // document.body.appendChild(link);
    // link.click();
    // link.remove();
  };

  const clickBaru = (e) => {
    e.preventDefault();

    // Konten Null
    setRemoveStatus(true);
    setWordsValue(3000);
    setJenisInputan("Ebook (PDF)");
    setTranslateBhs(options_translate[0]);
    setAbstrakCheck(false);

    setKontenVis(true);
    setLoadingVis(false);
    setDoneVis(false);

    setStart(false);

    props.resetToken("");
    props.resetStatus("");
    props.resetDetail("");
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [chapterPlay, setChapterPlay] = useState(filteredData[0].title);

  const handleClickChapter = (index, chapter) => {
    setActiveIndex(index); // Set div yang diklik sebagai aktif
    setChapterPlay("---");

    setLoadingAudio(true);
    setTimeout(() => {
      setLoadingAudio(false);
      setChapterPlay(chapter);
    }, 700);
  };

  return (
    <div className="container">
      <nav className="sidebar">
        <ul>
          <li>
            <div className="logoMarshall">
              <img src={logo} alt=""></img>
              <span className="nav-item">Marshall</span>
            </div>
          </li>
          <li id="create">
            <Link to={"/dashboard"}>
              <IoCreateOutline className="fas" />
              <span className="nav-item">Create</span>
            </Link>
          </li>
          <li id="requests">
            <Link to={"/requests"}>
              <AiOutlineHistory className="fas" />
              <span className="nav-item">View Requests History</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/settings"}>
              <BsGear className="fas" />
              <span className="nav-item">Settings</span>
            </Link>
          </li> */}
          <li id="elibrary">
            <Link to={"/eLibrary"}>
              <LuBookDown className="fas" />
              <span className="nav-item">E-Library</span>
            </Link>
          </li>
          <li id="coqa">
            <Link to={"/coqa"}>
              <LuFileQuestion className="fas" />
              <span className="nav-item">CoQa</span>
            </Link>
          </li>
          <li id="mapping">
            <Link to={"/mapping"}>
              <MdManageSearch className="fas" />
              <span className="nav-item">Mapping Dirkom 11</span>
            </Link>
          </li>
          <li id="audiolearning">
            <Link to={"/audiolearning"}>
              <MdSpatialAudio className="fas" />
              <span className="nav-item">Audio Learning</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/videolearning"}>
              <MdOutlineSlowMotionVideo className="fas" />
              <span className="nav-item">Video Learning</span>
            </Link>
          </li> */}
          <li id="contactus">
            <Link to={"/contactus"}>
              <LuContact2 className="fas" />
              <span className="nav-item">Contact Us</span>
            </Link>
          </li>
          <li className="logout">
            <Link to={"/"}>
              <MdLogout className="fas-logout" />
              <span className="nav-item-logout">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>

      <section className="main">
        <div className="container_audioLearning">
          {kontenVis && (
            <div>
              <h1 id="judul_audioLearning" style={{ marginBottom: "40px" }}>
                Audio Learning
              </h1>
              <div className="fullpage_audio">
                <div className="mainpage_audio">
                  <section>
                    <div style={{ marginBottom: "20px" }}>
                      <span className="span-items_audio">
                        Jenis Inputan (Any Language)
                      </span>
                      <Dropdown
                        className="dropDown-jenisInputan"
                        options={options_jenisInputan}
                        value={jenisInputan}
                        onChange={(e) => handleJenisChange(e)}
                      />
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <span className="span-items_audio">
                        Output Audio Language
                      </span>
                      <div className="tooltip">
                        <span className="simbolSpan">?</span>
                        <div className="tooltiptext">
                          Settings Keluaran Bahasa Audio yang akan digunakan
                          pada Audio Learning
                        </div>
                      </div>
                      <Dropdown
                        className="dropDown-jenisInputan"
                        options={options_translate}
                        value={translateBhs}
                        onChange={(e) => setTranslateBhs(e.value)}
                      />
                    </div>

                    <div
                      style={{
                        marginBottom: "20px",
                        width: "300px",
                        fontSize: "10px",
                      }}
                    >
                      <span style={{ color: "red" }}>*</span>
                      <span>
                        Audio Narator hanya tersedia untuk Bahasa Indonesia &
                        Inggris
                      </span>
                    </div>
                  </section>

                  <section
                    style={{
                      margin: `${abstrakCheck ? "20px" : "30px"} 0 0 50px`,
                    }}
                  >
                    <span className="span-judul_audio">Options</span>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <span className="span-items_audio">Abstrak PDF</span>
                      <div className="tooltip">
                        <span className="simbolSpan">?</span>
                        <div className="tooltiptext">
                          Settings Max Kata dalam pembuatan Audio Learning (Min
                          : 3000 Kata)
                        </div>
                      </div>
                      <input
                        className="checkBox_abstrakAudio"
                        type="checkbox"
                        checked={abstrakCheck}
                        onClick={onCheckAbstrak}
                      />
                    </div>

                    {abstrakCheck ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="number"
                          className="inputText_AbstrakPDF"
                          value={wordsValue}
                          onChange={handleInputWords}
                          onBlur={handleBlur}
                          style={{ textAlign: "center" }}
                          min="3000"
                        ></input>
                        <span style={{ marginLeft: "7px" }}>Words</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </section>
                </div>

                <div>
                  <span className="span-items_audio">File</span>
                  <span className="span-items_audio" style={{ color: "red" }}>
                    *
                  </span>
                  <DropFileInput
                    onFileChange={(e) => onFileChange(e)}
                    textjudul="Audio Learning"
                    width="500"
                    height="100"
                    widthlogo="25"
                    logoName={logoInput}
                    dropName={dropname}
                    accept={accFile}
                    multiple={false}
                    removeAll={removeStatus}
                    resetRemove={handleResetRemove}
                  />

                  <div className="space_audioBut">
                    <button
                      className="audioBut"
                      onClick={(e) => handleClickAudio(e)}
                    >
                      Convert to Audio
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {loadingSpinner && (
            <div>
              <span style={{ fontSize: "15px" }}>
                Masih Belum Masuk Antrian (Tunggu Upload Selesai)
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "15px" }}>
                  Progress Upload : {prosesUpload}% &nbsp;&nbsp;&nbsp;
                </span>
                <Spinner />
              </div>
            </div>
          )}

          {loadingVis && (
            <div
              style={{
                display: "grid",
                alignContent: "center",
                height: "80vh",
              }}
            >
              <div className="loading-container">
                <div
                  className="logo-spinner"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    className="logo-loading"
                    src={logo}
                    style={{ width: "320px", height: "120px" }}
                  ></img>
                  <img
                    className="gif-loading"
                    src={gifLogo}
                    style={{
                      width: "60px",
                      height: "60px",
                      marginLeft: "10px",
                    }}
                  ></img>
                </div>

                <div
                  style={{
                    display: "grid",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      marginBottom: "20px",
                      textAlign: "center",
                      fontSize: "15px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {statusAudio === "waiting"
                      ? "Tunggu Antrean Generate Module (Waiting)\nStatus Request Anda dapat di CEK di Tab Requests (Di Navigasi Panel Sebelah Kiri)"
                      : "Generate Module Anda sedang di Proses (Processing)\nStatus Request Anda dapat di CEK di Tab Requests (Di Navigasi Panel Sebelah Kiri)"}
                  </p>

                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      height: "40px",
                    }}
                  >
                    <button className="audioBut_baru" onClick={clickBaru}>
                      AUDIO BARU
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {doneVis && (
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  marginBottom: "30px",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Module Audio Player
              </span>

              <div className="judulFile_audio">
                <span
                  style={{
                    fontSize: "16px",
                    marginBottom: "15px",
                    fontWeight: "bold",
                  }}
                >
                  File Convert : {namaFile}
                </span>
              </div>

              {/* <div className="nowPlay_audio">Now Playing : {chapterPlay}</div> */}

              <div className="space_audioBut">
                {urlAudio && (
                  <audio controls style={{ zoom: "0.8" }}>
                    <source src={urlAudio} type="audio/wav" />
                    Your browser does not support the audio element.
                  </audio>
                )}
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                {urlAudio && (
                  <a
                    href={urlAudio}
                    style={{ textDecoration: "none", color: "white" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      className="audioBut"
                      onClick={console.log({ urlAudio })}
                      style={{ marginRight: "10px" }}
                    >
                      Download Audio
                    </button>
                  </a>
                )}

                <button className="audioBut" onClick={(e) => convertBaru(e)}>
                  Convert Audio Baru
                </button>
              </div>
            </div>

            // {loadingAudio ? (
            //   <div
            //     style={{
            //       display: "flex",
            //       justifyContent: "center",
            //       padding: "2px",
            //     }}
            //   >
            //     <Spinner />
            //   </div>
            // ) : (
            //   <div>
            //     <div className="space_audioBut">
            //       {urlAudio && (
            //         <audio controls style={{ zoom: "0.8" }}>
            //           <source src={urlAudio} type="audio/wav" />
            //           Your browser does not support the audio element.
            //         </audio>
            //       )}
            //     </div>

            //     <div style={{ display: "flex", justifyContent: "center" }}>
            //       {urlAudio && (
            //         <a
            //           href={urlAudio}
            //           style={{ textDecoration: "none", color: "white" }}
            //           target="_blank"
            //           rel="noopener noreferrer"
            //         >
            //           <button
            //             className="audioBut"
            //             onClick={console.log({ urlAudio })}
            //             style={{ marginRight: "10px" }}
            //           >
            //             Download Audio
            //           </button>
            //         </a>
            //       )}

            //       <button
            //         className="audioBut"
            //         onClick={(e) => convertBaru(e)}
            //       >
            //         Convert Audio Baru
            //       </button>
            //     </div>
            //   </div>
            // )}

            // <div>
            //   <div className="content_chapterAudio" id="content">
            //     <div className="judulFile_audio">
            //       <span
            //         style={{
            //           fontSize: "16px",
            //           marginBottom: "15px",
            //           fontWeight: "bold",
            //         }}
            //       >
            //         File Convert : {namaFile}
            //       </span>
            //     </div>
            //     {filteredData.map((item, no) => (
            //       <div
            //         key={no}
            //         className="chapterAudio-box"
            //         style={{
            //           backgroundColor:
            //             activeIndex === no ? "#d4d4d4" : "white",
            //         }} // Ganti warna sesuai state
            //       >
            //         <div>
            //           <h3>{item.title}</h3>
            //           <h3>Halaman : {item.index}</h3>
            //         </div>

            //         <div
            //           style={{
            //             display: "flex",
            //             width: "25rem",
            //             justifyContent: "end",
            //           }}
            //         >
            //           <button
            //             className="chapter_audioBut"
            //             onClick={() => handleClickChapter(no, item.title)}
            //           >
            //             Play
            //           </button>
            //         </div>
            //       </div>
            //     ))}
            //   </div>
            // </div>
            // </div>
          )}
        </div>
      </section>

      <span
        style={{
          display: "flex",
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "5px",
        }}
      >
        ~ Beta Version 1.0
      </span>
    </div>
  );
};

export default AudioLearning;
