import React from "react";
import "./Login.css";
import Spinner from "../Spinner.js";
import { Link } from "react-router-dom";
// Import Assets
import video from "../../LoginAssets/work.mp4";
import logo from "../../LoginAssets/itdri.png";
// Import Icon react-icon
import { FaUserShield } from "react-icons/fa";
import { BsFillShieldLockFill } from "react-icons/bs";
import { AiOutlineSwapRight } from "react-icons/ai";
import { MdMargin } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { clear } from "@testing-library/user-event/dist/clear.js";

const { useState, useEffect } = React;

const Login = ({ setIsAuthenticated, setUser }) => {
  const [dataUser, setDataUser] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  // useEffect(() => {

  //   const formData = new FormData();
  //   formData.append("n_row", "9999");
  //   formData.append("page", "1");

  //   axios({
  //     timeout: 360000,
  //     method: "post",
  //     url: process.env.REACT_APP_API_URL+"/list-user/",
  //     data: formData,
  //     headers: { "Content-Type": "multipart/form-data" },
  //   })
  //     .then(function (res) {
  //       let data = res.data;

  //       const newData = data.map((item) => ({
  //         id: item[0],
  //         nama: item[1],
  //         username: item[3],
  //         password: item[4],
  //       }));

  //       console.log("LOGIN : ", newData);
  //       setDataUser(newData);
  //     })
  //     .catch(function (res) {
  //       console.log(res);
  //       return "";
  //     });
  // }, []);

  useEffect(() => {
    function handleKeyDown(event) {
      // Jika kombinasi Shift + Y ditekan, jalankan fungsi keyPressed
      if (event.ctrlKey && event.shiftKey && event.key === "Y") {
        // Dapatkan elemen input username dan password
        const usernameInput = document.getElementById("username");
        const passwordInput = document.getElementById("password");

        // // Isi nilai input dengan username dan password yang diinginkan
        // if (usernameInput && passwordInput) {
        usernameInput.value = "yosef";
        passwordInput.value = "telkomgoe";
        // }

        const namaUser = "yosef";
        const passUser = "telkomgoe";

        console.log(namaUser);

        setUsername("yosef");
        setPassword("telkomgoe");
        altLogin(namaUser, passUser);
      } else if (event.ctrlKey && event.shiftKey && event.key === "Z") {
        const namaUser = "adi";
        const passUser = "telkomgoe";

        const usernameInput = document.getElementById("username");
        const passwordInput = document.getElementById("password");

        usernameInput.value = "adi";
        passwordInput.value = "telkomgoe";

        console.log(namaUser);

        setUsername("adi");
        setPassword("telkomgoe");
        altLogin(namaUser, passUser);
      } else if (event.ctrlKey && event.shiftKey && event.key === "X") {
        const namaUser = "agung";
        const passUser = "telkomgoe";

        const usernameInput = document.getElementById("username");
        const passwordInput = document.getElementById("password");

        usernameInput.value = "agung";
        passwordInput.value = "telkomgoe";

        console.log(namaUser);

        setUsername("agung");
        setPassword("telkomgoe");
        altLogin(namaUser, passUser);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener ketika komponen unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const altLogin = (user, pass) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("username", user);
    formData.append("password", pass);

    axios({
      timeout: 360000,
      method: "post",
      url: process.env.REACT_APP_API_URL + "/login-user/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (res) {
        let data = res.data;

        const newData = {
          id: data.id_user,
          nama: data.nama,
          username: data.username,
          password: data.password,
          status: data.status,
        };

        const timer = setTimeout(() => {
          setLoading(false);

          if (data.status == "success") {
            setColorStatus("green");
            setError("SUCCESS");

            setTimeout(() => {
              setIsAuthenticated(true);
              setUser(data);
              navigate("/dashboard");
            }, 1000);
          } else {
            setColorStatus("red");
            setError("USERNAME / PASSWORD INVALID");
          }

          // console.log("LOGIN : ", newData);
          setDataUser(newData);
        }, 2000); // 2 seconds

        return () => clearTimeout(timer);
      })
      .catch(function (res) {
        console.log(res);
        return "";
      });
  };

  // SECRET KEY

  const loginClick = (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    axios({
      timeout: 360000,
      method: "post",
      url: process.env.REACT_APP_API_URL + "/login-user/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (res) {
        let data = res.data;

        const newData = {
          id: data.id_user,
          nama: data.nama,
          username: data.username,
          password: data.password,
          status: data.status,
        };

        const timer = setTimeout(() => {
          setLoading(false);

          if (data.status == "success") {
            setColorStatus("green");
            setError("SUCCESS");

            setTimeout(() => {
              setIsAuthenticated(true);
              setUser(data);
              navigate("/dashboard");
            }, 1000);
          } else {
            setColorStatus("red");
            setError("USERNAME / PASSWORD INVALID");
          }

          // console.log("LOGIN : ", newData);
          setDataUser(newData);
        }, 2000); // 2 seconds

        return () => clearTimeout(timer);
      })
      .catch(function (res) {
        console.log(res);
        return "";
      });

    const user = dataUser.find(
      (user) => user.username === username && user.password === password
    );
  };

  const text = "Modular Advanced Revamp System\nwith Hyper Learning Loops";
  const [colorStatus, setColorStatus] = useState("red");

  // const addDataIntoCache = (e, cacheName, url, response) => {
  //   // Converting our response into Actual Response form
  //   e.preventDefault();
  //   const data = new Response(JSON.stringify(response));
  //   console.log(data);
  //   if ("caches" in window) {
  //     // Opening given cache and putting our data into it
  //     caches.open(cacheName).then((cache) => {
  //       cache.put(url, data);
  //       alert("Data Added into cache!");
  //     });
  //   }
  // };

  useEffect(() => {
    const clearCacheData = async () => {
      if ("caches" in window) {
        try {
          const cacheNames = await caches.keys();
          await Promise.all(
            cacheNames.map(async (cacheName) => {
              await caches.delete(cacheName);
            })
          );
          console.log("Cache cleared");
        } catch (err) {
          console.error("Failed to clear cache:", err);
        }
      } else {
        console.log("Cache API not supported in this browser");
      }
    };

    clearCacheData();
  }, []);

  // Set Show Password
  const [cekPass, setCekPass] = useState(false);

  const onCekPas = (e) => {
    setCekPass(!cekPass);
  };

  return (
    <div className="loginPage flex">
      <div className="container flex">
        <div className="videoDiv">
          <video src={video} autoPlay loop muted></video>

          <div className="textDiv">
            <h2 className="title">Marshall</h2>
            <p style={{ whiteSpace: "pre-line" }}>{text}</p>
          </div>

          <div className="footerDiv flex">
            <span className="text">Don't have an account?</span>
            <Link to={"/register"}>
              <button className="btn">Sign Up</button>
            </Link>
          </div>
        </div>

        <div className="formDiv flex">
          <div className="headerDiv">
            <img src={logo} alt="Logo Image"></img>
            <h3>Welcome Back!</h3>
          </div>

          <form action="" className="form grid">
            <div
              style={{
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
                marginBottom: "10px",
              }}
            >
              {loading ? (
                <Spinner />
              ) : (
                error && (
                  <div
                    className="loginStatus"
                    style={{ backgroundColor: colorStatus }}
                  >
                    <p style={{ color: "white", textAlign: "center" }}>
                      {error}
                    </p>
                  </div>
                )
              )}
            </div>

            <div className="inputDiv">
              <label htmlFor="username">Username</label>
              <div className="input flex">
                <FaUserShield className="icon" />
                <input
                  type="text"
                  id="username"
                  placeholder="Enter Username"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="password">Password</label>
              <div className="input flex">
                <BsFillShieldLockFill className="icon" />
                <input
                  type={!cekPass ? "password" : "text"}
                  id="password"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "start" }}>
              <input
                type="checkbox"
                checked={cekPass}
                onClick={onCekPas}
                style={{ marginLeft: "5px" }}
              />
              <span style={{ marginLeft: "5px" }}>Show Password</span>
            </div>

            {/* <Link to={'/dashboard'} >     
              
            </Link> */}
            <button type="submit" className="btn flex" onClick={loginClick}>
              <span>Login</span>
              <AiOutlineSwapRight className="icon" />
            </button>

            {/* <span className="forgotPassword">
              Forgot your password? <a href="">Click Here</a>
            </span> */}
          </form>
        </div>
      </div>
      <span
        style={{
          display: "flex",
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "10px",
        }}
      >
        ~ Beta Version 1.0
      </span>
    </div>
  );
};

export default Login;
