// DropFileInput.jsx
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./DropFileInput.css";
import { ImageConfig } from "./ImageConfig.js";

const DropFileInput = (props) => {
  // Logo Path
  // const logo = require('../assets/file.png');
  const logo = require("../assets/" + props.logoName);

  // Hidden Content
  const [contentVis, setContentVis] = useState(true);

  // Ambil Data

  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);

  const [styleSet, setStyleSet] = useState("hidden");

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFile = [...e.target.files];

    if (newFile.length > 1) {
      setStyleSet("scroll");
    } else {
      setStyleSet("hidden");
    }

    // if (newFile) {
    // 	const updatedList = [...fileList, newFile];
    // 	setFileList(updatedList);
    // 	props.onFileChange(updatedList);

    // 	console.log(updatedList);
    // }
    console.log("Drop Name : ", props.dropName);

    if (props.dropName === "PPTX") {
      if (
        e.target.files[0].type ==
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        setFileList([...e.target.files]);
        props.onFileChange([...e.target.files]);
        setContentVis(false);
      } else {
        alert(
          "Hanya File Powerpoint .pptx saja yang bisa di upload, harap ubah format ke .pptx"
        );
      }
    } else if (props.dropName === "PDF") {
      if (e.target.files[0].type == "application/pdf") {
        setFileList([...e.target.files]);
        props.onFileChange([...e.target.files]);
        setContentVis(false);
      } else {
        alert("Hanya file .PDF yang bisa di upload disini");
      }

      const banyakfile = e.target.files.length;
      if (banyakfile > 5) {
        console.log("Banyak File : ", banyakfile);
        alert("Saat Ini Max 5 Referensi Ebook");
      } else {
        let loops = 0;
  
        do {
          const file = e.target.files[loops];
  
          if (file) {
            // Membaca beberapa byte pertama dari file untuk memeriksa apakah itu PDF
            const reader = new FileReader();
            reader.onloadend = () => {
              const arr = new Uint8Array(reader.result).subarray(0, 4);
              let header = "";
              for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
              }
  
              // Memeriksa header file PDF yang biasanya dimulai dengan "25 50 44 46" atau "%PDF"
              if (header === "25504446") {
                console.log("File valid PDF:", file.name);
              } else {
                alert(file.name + " bukan PDF yang valid!");
                setFileList([]);
                props.onFileChange([]);
                setContentVis(true);
                // e.target.value = null; // Mengosongkan input jika file tidak valid
              }
            };
            reader.readAsArrayBuffer(file);
          }
  
          loops = loops + 1;
        } while (loops !== banyakfile);
      }
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.onFileChange(updatedList);

    if (updatedList.length < 1) setContentVis(true);
    else setContentVis(false);
  };

  useEffect(() => {
    if (props.removeAll == true) {
      setFileList([]);
      setContentVis(true);
      props.resetRemove();
    }
  }, [props.removeAll]);

  return (
    <>
      {contentVis && (
        <div
          id={props.id}
          ref={wrapperRef}
          className="drop-file-input"
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          style={{ width: props.width + "px", height: props.height + "px" }}
        >
          <div className="drop-file-input__label">
            <img style={{ width: props.widthlogo + "px" }} src={logo} alt="" />
            <p>Drag & Drop your {props.dropName} here</p>
          </div>
          {/* <input type="file" value="" onChange={onFileDrop} accept=".pptx" /> */}
          <input
            type="file"
            value=""
            onChange={onFileDrop}
            accept={props.accept}
            multiple={props.multiple}
          />
        </div>
      )}
      {fileList.length > 0 ? (
        <div
          className="drop-file-preview"
          style={{
            width: props.width + "px",
            height: props.height + "px",
            overflowY: styleSet,
          }}
        >
          {/* <p className="drop-file-preview__title">
							Ready to {props.textjudul}
						</p> */}
          {fileList.map((item, index) => (
            <div key={index} className="drop-file-preview__item">
              <img
                src={
                  ImageConfig[item.type.split("/")[1]] || ImageConfig["default"]
                }
                alt=""
              />
              <div className="drop-file-preview__item__info">
                <p>{item.name}</p>
                <p>{Math.floor((item.size / 1024) * 100) / 100} KB</p>
              </div>
              <span
                className="drop-file-preview__item__del"
                onClick={() => fileRemove(item)}
              >
                x
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
